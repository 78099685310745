import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// URL de la API y token de la empresa desde el entorno React
const apiUrl = process.env.REACT_APP_API_URL

// Componente principal AddUsers
export default function AddUsers() {
    // Estados
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [alert, setAlert] = useState('')
    const [tokens, setTokens] = useState([])
    const [tokensChecked, setTokensChecked] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [queryParameters] = useSearchParams()
    const [isEditUser, setIsEditUser] = useState(false)
    const [getIdUser, setGetIdUser] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        setUserName('')
        setPassword('')

        // Edit user
        let getID = queryParameters.get("ID")
        let getUser = queryParameters.get("login")
        let getCompany = queryParameters.get("company")
        if (getID && getUser && getCompany) {
            setGetIdUser(getID)
            setIsEditUser(true)
            setUserName(getUser)

            getCompany.split(',').map(value => {
                setTokensChecked(prevState => ({
                    ...prevState,
                    [`option${value}`]: true
                }))
                return null
            })

        }

        setIsLoading(true)
        const getTokens = async () => {
            try {
                var myHeaders = new Headers()
                myHeaders.append("Content-Type", "application/json")

                const response = await fetch(`${apiUrl}/getTokens`, {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                })

                const data = await response.json()
                if (response.ok) {
                    setTokens(data.result)
                    setIsLoading(false)
                } else {
                    setAlert([data, 'danger'])
                    console.error(data)
                }
            } catch (error) {
                setAlert([error, 'danger'])
                console.error('Error de red', error)
            }
        }
        getTokens()
    }, [queryParameters])


    const saveUser = async () => {

        setAlert()

        const selectedOptions = Object.keys(tokensChecked).filter(option => tokensChecked[option])

        let selectedTokens = ''
        selectedOptions.map((value, index) => {
            selectedTokens += index === 0 ? `${value.split('option')[1]}` : `,${value.split('option')[1]}`
            return null
        })

        try {
            var myHeaders = new Headers()
            myHeaders.append("Content-Type", "application/json")

            var raw = JSON.stringify({
                'id': getIdUser,
                'login': userName,
                'password': password,
                'company': selectedTokens
            })

            const response = await fetch(`${apiUrl}/${isEditUser ? 'updateUser' : 'addUser'}`, {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            })

            const data = await response.json()
            if (response.ok) {
                // console.log(data.correctResponse)

                if (data.correctResponse) {
                    navigate("/users")

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        width: 300,
                        backdrop: false,
                        text: data.result,
                        showConfirmButton: false,
                        timer: 2000
                    })

                    setUserName('')
                    setPassword('')

                } else {
                    setAlert([data.result, 'danger'])
                }

            } else {
                setAlert([data, 'danger'])
                console.error(data)
            }
        } catch (error) {
            setAlert([error, 'danger'])
            console.error('Error de red', error)
        }
    }

    const handleCheckboxChange = event => {
        const { name, checked } = event.target;
        setTokensChecked(prevState => ({
            ...prevState,
            [name]: checked
        }))
    }

    return (
        <div>
            <div className='flex flex-col md:flex-row justify-center'>
                <div className='flex flex-col'>
                    <label className='font-bold px-2'>{isEditUser ? 'Edit User' : 'Add User'}</label>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='m-2'>
                    <label htmlFor="userName" className="text-sm font-medium text-gray-600">
                        User
                    </label>
                    <div className="group">
                        <input type="text"
                            id="userName"
                            className="input"
                            name='userName'
                            value={userName}
                            onChange={(e) => {
                                setUserName(e.target.value)
                            }}
                            placeholder='' />
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='m-2'>
                    <label htmlFor="passWord" className="text-sm font-medium text-gray-600">
                        Password
                    </label>
                    <div className="group">
                        <input type="password"
                            id="passWordUser"
                            className="input"
                            name='passWordUser'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            placeholder=''
                            autoComplete="new-password" />
                    </div>
                </div>
            </div>

            <div className='flex flex-col md:flex-row justify-center mt-3'>
                <div className='flex flex-col'>
                    <label className='font-bold px-2'>Select token</label>
                </div>
            </div>

            <div className='w-full flex justify-center'>

                {
                    isLoading ?
                        <div className='flex justify-center items-center flex-col m-4'>
                            <Spinner animation="grow" variant="primary" />
                            <span className='text-dividerColor'>Loading...</span>
                        </div>
                        :
                        <ul className="w-3/5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg p-0">
                            {tokens.map((value, index) => {
                                return <li key={value.ID} className="w-full border-b border-gray-200 rounded-t-lg cursor-pointer">
                                    <div className="flex items-center ps-3 cursor-pointer">
                                        <input id={`token-${value.ID}`} name={`option${value.ID}`} type="checkbox" className="w-4 h-4 text-darkPrimaryColor bg-gray-100 border-gray-300 rounded focus:ring-darkPrimaryColor focus:ring-2" checked={tokensChecked[`option${value.ID}`] || ''} onChange={handleCheckboxChange} />
                                        <label htmlFor={`token-${value.ID}`} className="w-full py-3 pl-4 ms-2 text-sm font-medium text-gray-900">{value.name} <br /> {value.token}</label>
                                    </div>
                                </li>
                            })}
                        </ul>
                }
            </div>

            <div className='flex justify-center'>
                {alert && (
                    <Alert variant={alert[1]} className='text-sm'>
                        {alert[0]}
                    </Alert>
                )}
            </div>

            <div className='w-full flex justify-center'>
                <Button className="bg-primaryColor" onClick={() => saveUser()}>
                    <span className='mx-3'>{isEditUser ? 'Edit' : 'Save'}</span>
                </Button>
            </div>
        </div >
    )
}