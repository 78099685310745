import React, { useEffect, useState } from 'react'
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert'
import imgLock from '../../images/lock.svg'
import { useLocation } from 'react-router'

const apiUrl = process.env.REACT_APP_API_URL

const Login = ({ onLogin, statusLogin, setLoggedIn }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const location = useLocation()

    useEffect(() => {
        const storedTokens = JSON.parse(localStorage.getItem('companyTokens'))

        // Acción a realizar cuando cambia la ruta
        async function validedToken() {
            for (let i = 0; i < storedTokens.length; i++) {
                try {
                    var myHeaders = new Headers()
                    myHeaders.append("Content-Type", "application/json")

                    var raw = JSON.stringify({
                        "URL": `/api/invoice.details.list?authenticationToken=${storedTokens[i]}`
                    })

                    const response = await fetch(`${apiUrl}`, {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    })

                    if (response.ok) {
                        setLoggedIn(true)
                        break
                    } else {
                        setLoggedIn(false)
                        setError('Error Token no valido')
                    }
                } catch (error) {
                    setLoggedIn(false)
                    setError('Error de red. Por favor, inténtalo de nuevo.')
                    console.error('Error de red', error)
                }
            }
        }

        storedTokens && validedToken()

    }, [location, setError, setLoggedIn])


    const handleLogin = async (e) => {
        e.preventDefault();

        // Validar campos vacíos
        if (!username || !password) {
            setError('Por favor, completa todos los campos.')
        } else {

            try {
                var myHeaders = new Headers()
                myHeaders.append("Content-Type", "application/json")

                var raw = JSON.stringify({
                    'login': username,
                    'password': password
                })

                const response = await fetch(`${apiUrl}/login`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                })

                const data = await response.json()
                if (response.ok) {
                    const getTokens = data.tokens
                    console.log(data.tokens)

                    const tokens = []
                    const companyCode = []
                    getTokens.map(value => {
                        tokens.push(value.token)
                        companyCode.push(value.companyCode)
                        return null
                    })

                    localStorage.setItem('companyTokens', JSON.stringify(tokens))
                    localStorage.setItem('companyCodes', JSON.stringify(companyCode))
                    localStorage.setItem('user', username)
                    onLogin()
                } else {
                    setError(data)
                    console.error(data)
                }
            } catch (error) {
                setError(error)
                console.error('Error de red', error)
            }
        }
    }

    return (
        <>
            {!statusLogin ?
                <div className="containerLogin min-h-screen flex items-center justify-center">
                    <div className="formLogin relative max-w-md w-full p-8 pb-28 bg-white rounded-3xl shadow-2xl">

                        <img src={imgLock} alt="Lock" className='h-32' />

                        {error && (
                            <Alert variant='danger' className='text-sm'>
                                {error}
                            </Alert>
                        )}

                        <form className="grid grid-cols-1 gap-6" autoComplete="off">

                            <label htmlFor="username" className="text-sm font-medium text-gray-600">
                                Nombre de usuario
                            </label>
                            <div className="group  w-full">
                                <FontAwesomeIcon icon={faUser} className='icon' />
                                <input type="text"
                                    id="username"
                                    className="inputLogin"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value)
                                        setError('')
                                    }}
                                    placeholder='Usuario'
                                    autoComplete="off" />
                            </div>

                            <label htmlFor="password" className="text-sm font-medium text-gray-600">
                                Contraseña
                            </label>
                            <div className="group">
                                <FontAwesomeIcon icon={faLock} className='icon' />
                                <input type="password"
                                    id="password"
                                    className="inputLogin"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                        setError('')
                                    }}
                                    placeholder='Contraseña'
                                    autoComplete="off" />
                            </div>

                            <button
                                className="btnLogin text-white py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-300 transition-all duration-300"
                                onClick={handleLogin}
                            >
                                <span>Iniciar sesión</span>
                            </button>
                        </form>

                        <svg className='absolute w-full right-0 bottom-0 rounded-b-3xl' viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#03A9F4" d="M 0 210.635 L 34.3 198.645 C 68.6 187.102 137 162.449 206 186.767 C 274.3 210.635 343 282.355 411 282.355 C 480 282.355 549 210.635 617 156.846 C 685.7 103.056 754 67.197 823 49.268 C 891.4 31.338 960 31.338 1029 67.197 C 1097.1 103.056 1166 174.776 1234 186.767 C 1302.9 198.309 1371 151.243 1406 126.926 L 1440 103.056 L 1440 354.073 L 1405.7 354.073 C 1371.4 354.073 1303 354.073 1234 354.073 C 1165.7 354.073 1097 354.073 1029 354.073 C 960 354.073 891 354.073 823 354.073 C 754.3 354.073 686 354.073 617 354.073 C 548.6 354.073 480 354.073 411 354.073 C 342.9 354.073 274 354.073 206 354.073 C 137.1 354.073 69 354.073 34 354.073 L 0 354.073 L 0 210.635 Z" />
                        </svg>
                    </div>
                </div>
                :
                <></>
            }
        </>
    );
};

export default Login;
