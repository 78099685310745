import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
// import Home from './components/Home/Home';
import Guides from './components/Guides/Guides';
import Navbar from './components/Navbar/Navbar';
import Error404 from './components/Error404/404';
import { useMediaQuery } from '@react-hook/media-query';
import StatusGuides from './components/StatusGuides/StatusGuides';
import Users from './components/Users/Users';
import AddUsers from './components/AddUsers/AddUsers';
import AddToken from './components/AddToken/AddToken';
import GetBoxes from './components/GetBoxes/GetBoxes';
import Options from './components/StatusGuides/Options';
import StatusCarriers from './components/StatusGuides/StatusCarriers';
import StatusBoxes from './components/StatusGuides/StatusBoxes';

const App = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [admin, setAdmin] = useState(false);

  const handleLogin = () => {
    // Acciones después de iniciar sesión
    setLoggedIn(true);
  };

  const handleLogout = () => {
    // Acciones después de cerrar sesión
    // Elimina la información de autenticación del almacenamiento local
    localStorage.clear();
    setLoggedIn(false);
  }

  return (
    <Router>
      <div className={!isMobile ? 'flex' : ''}>
        {isLoggedIn && <Navbar onLogout={handleLogout} admin={setAdmin} />}

        <Login onLogin={handleLogin} statusLogin={isLoggedIn} setLoggedIn={setLoggedIn} />

        <Routes>
          <Route path="/" element={isLoggedIn && <Navigate to="/guides" />} />

          {/* <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/home" /> : <Login onLogin={handleLogin} />}
          /> */}

          {/* <Route
            path="/home"
            element={isLoggedIn && <Home onLogout={handleLogout} />}
          /> */}

          <Route
            path="/guides"
            element={isLoggedIn &&
              <div className='w-full flex flex-column p-2 h-screen overflow-auto'>
                <Guides onLogout={handleLogout} />
              </div>
            }
          />

          <Route
            path="/statusGuides"
            element={isLoggedIn &&
              <div className='w-full flex flex-column p-2 h-screen overflow-auto'>
                <Options onLogout={handleLogout} />
              </div>
            }
          />

          <Route
            path="/statusGuides/guides"
            element={isLoggedIn &&
              <div className='w-full flex flex-column p-2 h-screen overflow-auto'>
                <StatusGuides onLogout={handleLogout} />
              </div>
            }
          />

          <Route
            path="/statusGuides/carriers"
            element={isLoggedIn &&
              <div className='w-full flex flex-column p-2 h-screen overflow-auto'>
                <StatusCarriers onLogout={handleLogout} />
              </div>
            }
          />

          <Route
            path="/statusGuides/boxesLogs"
            element={isLoggedIn &&
              <div className='w-full flex flex-column p-2 h-screen overflow-auto'>
                <StatusBoxes onLogout={handleLogout} />
              </div>
            }
          />

          <Route
            path="/users"
            element={isLoggedIn && admin ?
              <div className='w-full flex flex-column justify-center p-2 h-screen overflow-auto'>
                <Users onLogout={handleLogout} />
              </div>
              :
              <Error404 />
            }
          />

          <Route
            path="/users/addUsers"
            element={isLoggedIn && admin ?
              <div className='w-full flex flex-column justify-center p-2 h-screen overflow-auto'>
                <AddUsers onLogout={handleLogout} />
              </div>
              :
              <Error404 />
            }
          />

          <Route
            path="/addToken"
            element={isLoggedIn && admin ?
              <div className='w-full flex flex-column justify-center p-2 h-screen overflow-auto'>
                <AddToken onLogout={handleLogout} />
              </div>
              :
              <Error404 />
            }
          />

          <Route
            path="/getBoxes"
            element={isLoggedIn && admin ?
              <div className='w-full flex flex-column justify-center p-2 h-screen overflow-auto'>
                <GetBoxes onLogout={handleLogout} />
              </div>
              :
              <Error404 />
            }
          />

          <Route path="/*" element={isLoggedIn && <Error404 />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
