import { useEffect, useState } from 'react'
import './Guides.css'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Swal from 'sweetalert2'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faX } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component'
import useSound from 'use-sound'
import audioIncorrectCode from '../../assets/audio/incorrectCode.mp3'
import audioOrderFinished from '../../assets/audio/orderFinished.mp3'
import audioAlreadyBoxScan from '../../assets/audio/alreadyBoxScan.mp3'

// URL de la API y token de la empresa desde el entorno React
const apiUrl = process.env.REACT_APP_API_URL

// Componente principal Guides
export default function Guides() {
    // Estados
    const [orderNum, setOrderNum] = useState('')
    const [alert, setAlert] = useState('')
    const [boxToSearchError, setBoxToSearchError] = useState('')
    const [totalBoxes, setTotalBoxes] = useState()
    const [countBoxScan, setCountBoxScan] = useState(0)
    const [pendingBoxScan, setPendingBoxScan] = useState(0)
    const [readyBoxScan, setReadyBoxScan] = useState(0)
    const [staticPendingBoxScan, setStaticPendingBoxScan] = useState()
    const [boxes, setBoxes] = useState([])
    const [readyBoxes, setReadyBoxes] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [boxToSearch, setBoxToSearch] = useState('')
    const [readCodes, setReadCodes] = useState(false)
    const [finishProcess, setFinishProcess] = useState(false)
    const [alertStatusBoxes, setAlertStatusBoxes] = useState(false)
    const [companyLocationId, setCompanyLocationId] = useState('')
    const [companyTokens, setCompanyTokens] = useState('')
    const [playIncorrectCode] = useSound(audioIncorrectCode)
    const [playOrderFinished] = useSound(audioOrderFinished)
    const [playAlreadyBoxScan] = useSound(audioAlreadyBoxScan)
    const [lots, setLots] = useState([])
    const [user, setUser] = useState('')

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    const onRowClicked = (row, event) => {
        setOrderNum(row.boxCode)
        navigator.clipboard.writeText(row.boxCode)
    }

    const columns = [
        {
            name: 'Order',
            selector: row => row.numOrder,
            wrap: true,
        },
        {
            name: 'boxCode',
            selector: row => row.boxCode,
            wrap: true,
        },
        {
            name: 'detailId',
            selector: row => row.detailId,
            wrap: true
        },
        {
            name: 'statusId',
            selector: row => row.statusId,
            wrap: true
        },
        {
            name: 'lotNumber',
            selector: row => row.lotNumber,
            wrap: true
        },
        {
            name: 'Client',
            selector: row => row.customerName,
            wrap: true
        },
        {
            name: 'Carrier',
            selector: row => row.carrierName,
            wrap: true
        },
        // {
        //     name: 'Action',
        //     selector: row => row.reader && <FontAwesomeIcon icon={faX} onClick={() => removeCode(row)} className=' bg-dividerColor hover:bg-darkPrimaryColor hover:text-white cursor-pointer rounded m-1 px-2 py-2 fa-1x' />,
        //     wrap: true
        // }
    ]

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#0288D1',
                whiteSpace: 'wrap'
            }
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                backgroundColor: '#0288D1',
                color: 'white',
            }
        },
        rows: {
            style: {
                backgroundColor: '#e2e3e5',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(2 136 209 / 20%)',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
        cells: {
            style: {},
        },
    }

    const conditionalRowStyles = [
        {
            when: row => row.reader,
            style: {
                backgroundColor: '#B3E5FC',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.type === 1,
            style: {
                backgroundColor: '#f59e0b',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.statusId === 3,
            style: {
                backgroundColor: '#22c55e',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]

    useEffect(() => {
        const tokens = localStorage.getItem('companyTokens')
        const user = localStorage.getItem('user')
        setCompanyTokens(tokens)
        setUser(user)
    }, [companyTokens])

    // Efecto para verificar si se han leído todas las cajas
    useEffect(() => {
        if (staticPendingBoxScan === readyBoxes.length && readyBoxes.length > 0) {
            alertAllBoxesComplete()

            finishedReading()

            // readyBoxes.length && setFinishProcess(true)
        } else {
            setFinishProcess(false)
        }
    }, [staticPendingBoxScan, readyBoxes, pendingBoxScan])

    useEffect(() => {
        setReadyBoxScan(totalBoxes - pendingBoxScan)
    }, [totalBoxes, pendingBoxScan])

    const alertAllBoxesComplete = () => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            width: 300,
            backdrop: false,
            title: "Completado",
            text: "Todas las cajas fueron leídas",
            showConfirmButton: false,
            timer: 2000
        })
    }

    // Función para buscar la información de una orden
    const searchOrder = async (e, finishOrder, finishOrderNum) => {
        e && e.preventDefault()

        console.log(`CODIGO: ${orderNum}`)

        let getNumberOrder = orderNum ? orderNum.toUpperCase() : ''
        if (finishOrder) {
            getNumberOrder = finishOrderNum
        }

        setAlert('')

        if (getNumberOrder) {
            if (getNumberOrder.slice(0, 2) === 'MF') {
                let boxCode = getNumberOrder
                setBoxToSearch(boxCode)
                searchBox(boxCode)
            } else {
                let order = getNumberOrder

                if (!/^\d+$/.test(order)) {
                    order = order.slice(2, order.length)
                }

                setIsLoading(true)
                setCountBoxScan(0)
                setBoxToSearch('')
                setReadCodes(false)
                setBoxes([])
                setReadyBoxes([])
                setStaticPendingBoxScan(null)
                setFinishProcess(false)
                setAlertStatusBoxes(false)

                const storedTokens = JSON.parse(companyTokens)

                for (let i = 0; i < storedTokens.length; i++) {
                    try {
                        var raw = JSON.stringify({
                            "URL": `/api/invoice.details.list?authenticationToken=${storedTokens[i]}&order=${order}`
                        })

                        // Consulta a la API para obtener detalles de la factura
                        const response = await fetch(`${apiUrl}`, {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        })

                        const data = await response.json()
                        if (response.ok) {
                            if (data.invoices.length) {
                                const orderDate = data.invoices[0].shipDate.split('T')[0]

                                try {
                                    var raw2 = JSON.stringify({
                                        "URL": `/api/pickticket.details.list?authenticationToken=${storedTokens[i]}&orderDate=${orderDate}&order=${order}`
                                    })

                                    // Consulta a la API para obtener detalles del pickticket
                                    const response = await fetch(`${apiUrl}`, {
                                        method: 'POST',
                                        headers: myHeaders,
                                        body: raw2,
                                        redirect: 'follow'
                                    })

                                    const data = await response.json()
                                    if (response.ok) {
                                        if (data.message === 'OK') {
                                            const totalBoxes = data.picktickets[0].totalBoxes
                                            const details = data.picktickets[0].details
                                            const companyId = data.picktickets[0].locationId
                                            const numOrder = data.picktickets[0].number
                                            const carriersName = data.picktickets[0].carrierName
                                            const customerName = data.picktickets[0].customerName

                                            setTotalBoxes(totalBoxes)
                                            setPendingBoxScan(totalBoxes)
                                            showListBoxes(details, totalBoxes, numOrder, carriersName, customerName)
                                            setCompanyLocationId(companyId)
                                            setReadCodes(true)
                                            setIsLoading(false)
                                            setAlert('')
                                            break
                                        } else {
                                            setAlert([data.message, 'danger'])
                                        }
                                    } else {
                                        setAlert([data.message, 'danger'])
                                    }

                                } catch (error) {
                                    setAlert(['Error de red. Por favor, inténtalo de nuevo.', 'danger']);
                                    console.error('Error de red', error);
                                    setIsLoading(false)
                                }
                            } else {
                                setAlert(['Esta orden no tiene información', 'danger'])
                            }
                        } else {
                            setAlert([data.message, 'danger'])
                        }

                        setIsLoading(false)
                    } catch (error) {
                        setAlert(['Error de red. Por favor, inténtalo de nuevo.', 'danger'])
                        setIsLoading(false)
                    }
                }
            }

            setOrderNum('')

        } else {
            setAlert(['Campos vacíos', 'danger'])
        }
    }

    // Función para mostrar la lista de cajas
    const showListBoxes = (details, boxes, numOrder, carrierName, customerName) => {
        let listBoxes = []
        let getLots = []
        let counter = 0

        setLots([])

        for (const value of details) {
            for (const box of value.boxes) {
                box.numOrder = numOrder
                box.carrierName = carrierName
                box.customerName = customerName

                if (box.statusId === 3) {
                    counter++
                } else {
                    getLots.push(box.lotNumber)
                }

                listBoxes.push(box)
            }
        }

        setAlertStatusBoxes(counter === listBoxes.length)
        setPendingBoxScan(boxes - counter)
        setStaticPendingBoxScan(boxes - counter)
        setBoxes(listBoxes)
        setLots(getLots)
    }

    // Función para buscar una caja específica
    const searchBox = async (box) => {
        setBoxToSearchError('')

        if ((countBoxScan + 1) <= totalBoxes) {
            if (box) {

                let otherBox = false

                for (let i = 0; i < boxes.length; i++) {
                    if (boxes[i].boxCode === box) {
                        if (boxes[i].reader) {
                            // Alerta de caja ya leída
                            // Swal.fire({
                            //     position: "top-end",
                            //     icon: "error",
                            //     width: 300,
                            //     backdrop: false,
                            //     text: "Caja ya escaneada",
                            //     showConfirmButton: false,
                            //     timer: 2000
                            // })
                            setAlert(['Caja ya escaneada', 'success'])
                            playAlreadyBoxScan()
                        } else if (boxes[i].statusId === 3) {
                            // Alerta de caja ya gestionada por el almacén
                            // Swal.fire({
                            //     position: "top-end",
                            //     icon: "error",
                            //     width: 300,
                            //     backdrop: false,
                            //     text: "Caja ya gestionada por el almacén",
                            //     showConfirmButton: false,
                            //     timer: 2000
                            // })
                            setAlert(['Caja ya gestionada por el almacén', 'danger'])
                            playAlreadyBoxScan()
                        } else {

                            let getLotNumber = boxes[i].lotNumber
                            addRemoveLots(getLotNumber, 'remove')

                            setCountBoxScan(countBoxScan + 1)
                            setPendingBoxScan(pendingBoxScan - 1)

                            const editBoxes = boxes
                            editBoxes[i].reader = true

                            setBoxes(editBoxes)

                            const addReadyBox = readyBoxes
                            addReadyBox.push({ boxCode: boxes[i].boxCode, reader: true })

                            // Alerta de código encontrado
                            // Swal.fire({
                            //     position: "top-end",
                            //     icon: "success",
                            //     width: 300,
                            //     backdrop: false,
                            //     text: "Código escaneado",
                            //     showConfirmButton: false,
                            //     timer: 1500
                            // })
                            setAlert(['Código escaneado', 'success'])

                            changeStatusBox(box, getLotNumber)

                        }
                        break
                    }

                    if ((i + 1) === boxes.length) {
                        otherBox = true
                    }
                }

                // Caja de otro lote
                if (otherBox) {
                    if (staticPendingBoxScan) {
                        try {
                            var raw = JSON.stringify({
                                'boxCode': box
                            })

                            const response = await fetch(`${apiUrl}/searchBoxCode`, {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            })

                            const data = await response.json()
                            if (response.ok) {
                                // console.log(data)

                                let boxFound = data.found
                                let status = data.status
                                if (boxFound && status === 'Received Warehouse') {

                                    validSameLot(data)

                                } else {
                                    playIncorrectCode()
                                }

                            } else {
                                setAlert([data.message, 'danger'])
                            }

                        } catch (error) {
                            setAlert(['Error de red. Por favor, inténtalo de nuevo.', 'danger'])
                            console.error('Error de red', error)
                        }
                    } else {
                        alertAllBoxesComplete()
                    }
                }
            } else {
                setBoxToSearchError('Campo boxCode vacío')
            }
        } else {
            // Alerta de total de cajas ya leídas
            Swal.fire({
                position: "top-end",
                icon: "success",
                width: 300,
                backdrop: false,
                text: "Total de cajas ya leídas",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const changeStatusBox = async (box, lot) => {
        const storedTokens = JSON.parse(companyTokens)

        let tokenUsed = ''
        for (let i = 0; i < storedTokens.length; i++) {
            try {
                let raw = JSON.stringify({
                    "URL": "/api/box.warehouse.ship",
                    "method": "post",
                    "body": {
                        "authenticationToken": `${storedTokens[i]}`,
                        "boxCode": `${box}`,
                        "companyLocationId": `${companyLocationId}`
                    }
                })
                // Cambiar estado de caja
                const response = await fetch(`${apiUrl}`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                })

                const data = await response.json()

                if (response.ok) {
                    setAlert('')
                    tokenUsed = storedTokens[i]
                    break
                } else {
                    setAlert([data.message, 'danger'])
                }

            } catch (error) {
                setAlert(['Error de red. Por favor, inténtalo de nuevo.', 'danger'])
                console.error('Error de red', error)
            }
        }

        try {
            var raw = JSON.stringify({
                'lotNumber': lot,
                'boxCode': box,
                'user': user,
                'token': tokenUsed
            })

            await fetch(`${apiUrl}/logScanBox`, {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            })
        } catch (error) {
            console.error('Error de red', error)
        }

    }

    const validSameLot = (data) => {

        // data.lotNumber = '18945'
        let lotNumber = data.lotNumber

        let allLots = lots
        let noFountLot = false
        for (let i = 0; i < allLots.length; i++) {
            if (allLots[i] === lotNumber) {
                // console.warn('Si es del mismo lote')
                addBoxToTable(data)
                changeStatusBox(data.boxCode, data.lotNumber)
                break
            }

            if ((i + 1) === allLots.length) {
                noFountLot = true
            }
        }

        if (noFountLot) {
            // console.error('No es del mismo lote')
            playIncorrectCode()
        }
    }

    const addRemoveLots = (lotNumber, action) => {
        let allLots = lots

        if (action === 'add') {
            allLots.push(lotNumber)
            setLots(allLots)
        } else {
            for (let j = 0; j < allLots.length; j++) {
                if (allLots[j] === lotNumber) {
                    if (action === 'remove') {
                        allLots.splice(j, 1)
                        break
                    } else {
                        break
                    }
                }
            }

            setLots(allLots)
        }
    }

    // Función para quitar un código del listado
    // const removeCode = (code) => {
    //     const box = code.boxCode
    //     const type = code.type

    //     const swalWithBootstrapButtons = Swal.mixin({
    //         customClass: {
    //             confirmButton: "btn btn-success ml-2",
    //             cancelButton: "btn btn-danger"
    //         },
    //         buttonsStyling: false
    //     })
    //     swalWithBootstrapButtons.fire({
    //         title: type ? "Quitar boxCode del listado" : "Quitar como completado",
    //         text: `boxCode: ${box}`,
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "Quitar",
    //         cancelButtonText: "Cancelar",
    //         reverseButtons: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             setCountBoxScan(countBoxScan - 1)
    //             setPendingBoxScan(pendingBoxScan + 1)

    //             for (let i = 0; i < readyBoxes.length; i++) {
    //                 if (readyBoxes[i].boxCode === box) {
    //                     const getReadyBoxes = readyBoxes
    //                     let remove = getReadyBoxes.filter((valor) => valor !== readyBoxes[i]);
    //                     setReadyBoxes(remove)

    //                     if (type) {
    //                         getReadyBoxes.splice(i, 1)
    //                     }
    //                     break
    //                 }
    //             }

    //             for (let i = 0; boxes[i] && i < boxes.length; i++) {
    //                 if (boxes[i].boxCode === box) {
    //                     const show = boxes
    //                     show[i].reader = false
    //                     setBoxes(show)

    //                     addRemoveLots(boxes[i].lotNumber, 'add')

    //                     if (type) {
    //                         show.splice(i, 1)
    //                     }
    //                     break
    //                 }
    //             }
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             Swal.close()
    //         }
    //     })
    // }

    // Función para manejar la finalización de la lectura
    const finishedReading = async () => {
        playOrderFinished()

        searchOrder(null, true, boxes[0].numOrder)
    }

    const addBoxToTable = (dataBox) => {
        let boxCodeFound = dataBox

        boxCodeFound.reader = true
        boxCodeFound.inOrder = false
        boxCodeFound.type = 1
        readyBoxes.push(boxCodeFound)
        boxes.push(boxCodeFound)

        // addRemoveLots(boxCodeFound.lotNumber, 'add')
        addRemoveLots(boxCodeFound.lotNumber, 'remove')

        setBoxToSearch('')
        setCountBoxScan(countBoxScan + 1)
        setPendingBoxScan(pendingBoxScan - 1)
    }

    return (
        <>
            <form className='my-2'>
                <div className='w-full flex justify-center'>
                    <div className='m-2'>
                        <label htmlFor="numOrder" className="text-sm font-medium text-gray-600">
                            Order or BoxCode
                        </label>
                        <div className="group">
                            <input type="text"
                                id="numOrder"
                                className="input"
                                name='numOrder'
                                value={orderNum}
                                onChange={(e) => {
                                    setOrderNum(e.target.value)
                                    setAlert('')
                                }}
                                placeholder=''
                                autoComplete="off"
                                autoFocus />
                        </div>
                    </div>
                </div>

                <div className='flex justify-center'>
                    {alert && (
                        <Alert variant={alert[1]} className='text-sm'>
                            {alert[0]}
                        </Alert>
                    )}
                </div>

                <div className='flex justify-center'>
                    {isLoading ?
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='pl-2'>Cargando...</span>
                        </Button>
                        :
                        <>
                            <Button type='submit' className="bg-primaryColor" onClick={(e) =>
                                searchOrder(e)
                            }>Buscar</Button>
                        </>
                    }
                </div>
            </form >

            {
                readCodes ?
                    <div>

                        {
                            alertStatusBoxes ?
                                <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 mx-8" role="alert">
                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div>
                                        <span className="font-medium">Orden Completada!<br /></span> Todas las cajas de esta orden tienen un código de estado 3, el cual indica que ya fueron enviadas desde el almacén.
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        < div className='flex justify-center my-4' >
                            <div className='mx-6'>
                                <span className='font-bold'>Total</span>
                                <div className='text-center text-5xl font-bold text-neutral-600'>{totalBoxes}</div>
                            </div>
                            <div className='mx-6'>
                                <span className='font-bold'>Ready</span>
                                <div className='text-center text-5xl font-bold text-green-500'>{readyBoxScan}</div>
                            </div>
                            <div className='mx-6'>
                                <span className='font-bold'>Pending</span>
                                <div className='text-center text-5xl font-bold text-red-400'>{pendingBoxScan}</div>
                            </div>
                            <div className='mx-6'>
                                <span className='font-bold'>Scanned</span>
                                <div className='text-center text-5xl font-bold text-darkPrimaryColor'>{countBoxScan}</div>
                            </div>
                        </div >

                        <div className='hidden'>
                            <div className='w-full flex justify-center'>
                                <div className='m-2'>
                                    <label htmlFor="codeBox" className="text-sm font-medium text-gray-600">
                                        boxCode
                                    </label>
                                    <div className="group">
                                        <input type="text"
                                            id="codeBox"
                                            className="input"
                                            value={boxToSearch}
                                            onChange={(e) => {
                                                setBoxToSearch(e.target.value)
                                                setBoxToSearchError('')
                                            }}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    searchBox()
                                                }
                                            }}
                                            placeholder='boxCode'
                                            autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                {boxToSearchError && (
                                    <Alert variant='danger' className='text-sm'>
                                        {boxToSearchError}
                                    </Alert>
                                )}
                            </div>
                            <div className='flex justify-center'>
                                <Button className="bg-primaryColor" onClick={() => searchBox()}>Buscar</Button>
                            </div>
                        </div>

                        {
                            finishProcess ?
                                <div className='mt-10 flex justify-center'>
                                    <Button variant="success" onClick={() => finishedReading()}>Finalizar lectura</Button>
                                </div>
                                :
                                <></>
                        }


                        <div className='text-center my-4'>
                            <div className='mt-5 mb-2'>
                                <span className="bg-dividerColor text-primaryText text-sm font-medium me-2 px-2.5 py-0.5 rounded ">Ready to scan</span>
                                <span className="bg-lightPrimaryColor text-primaryText text-sm font-medium me-2 px-2.5 py-0.5 rounded ">Scanned</span>
                                <span className="bg-green-500 text-primaryText text-sm font-medium me-2 px-2.5 py-0.5 rounded ">Already scanned</span>
                                <span className="bg-amber-500 text-primaryText text-sm font-medium me-2 px-2.5 py-0.5 rounded ">Box swap</span>
                            </div>

                            <div>
                                <div className='border-2 border-white rounded-2xl'>

                                    <DataTable
                                        columns={columns}
                                        data={boxes}
                                        customStyles={customStyles}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={onRowClicked}
                                        conditionalRowStyles={conditionalRowStyles}
                                        dense
                                    />

                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    <></>
            }
        </>
    )
}