import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Swal from 'sweetalert2'

// URL de la API y token de la empresa desde el entorno React
const apiUrl = process.env.REACT_APP_API_URL

// Componente principal GetBoxes
export default function GetBoxes() {
    // Estados
    const [alert, setAlert] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const getBoxes = async () => {

        setIsLoading(true)

        Swal.fire({
            title: 'Running process',
            text: 'Do not close browser tab',
            allowEnterKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        try {
            var myHeaders = new Headers()
            myHeaders.append("Content-Type", "application/json")

            const response = await fetch(`${apiUrl}/getBoxes`, {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            })

            const data = await response.json()
            if (response.ok) {
                setAlert(['Finished process', 'success'])
                setIsLoading(false)
                Swal.close()
            } else {
                setAlert([data, 'danger'])
                console.error(data)
            }
        } catch (error) {
            setAlert([error, 'danger'])
            console.error('Error de red', error)
        }
    }

    return (
        <div>
            <div className='flex flex-col md:flex-row justify-center'>
                <div className='flex flex-col'>
                    <label className='font-bold px-2'>Get All Boxes From AWB</label>
                </div>
            </div>

            <div className='w-full flex justify-center mt-3 mb-4'>
                {
                    isLoading ?
                        <div className='flex justify-center items-center flex-col m-4'>
                            <Spinner animation="grow" variant="primary" />
                            <span className='text-dividerColor'>Loading...</span>
                        </div>
                        :
                        <Button className="bg-primaryColor" onClick={() => getBoxes()}>
                            <span className='mx-3'>Execute process</span>
                        </Button>
                }
            </div>

            <div className='w-full flex justify-center mt-3 mb-4'>
                {alert && (
                    <Alert variant={alert[1]} className='text-sm'>
                        {alert[0]}
                    </Alert>
                )}
            </div>
        </div>
    )
}