import './404.css';
import { Link } from 'react-router-dom';

export default function Error404() {
    return (
        <div className='w-full flex'>
            <div className='w-full bottom-24 flex justify-center items-center'>
                <div className='mt-10'>
                    <div className='w-full flex justify-center'>
                        <div className='w-1/12 mx-2 first-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill='#475569' d="M189 77.6c7.5-16 .7-35.1-15.3-42.6s-35.1-.7-42.6 15.3L3 322.4c-4.7 9.9-3.9 21.5 1.9 30.8S21 368 32 368H256v80c0 17.7 14.3 32 32 32s32-14.3 32-32V368h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320V160c0-17.7-14.3-32-32-32s-32 14.3-32 32V304H82.4L189 77.6z" /></svg>
                        </div>

                        <div className='w-1/12 mx-2 center-0'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 384 512"><path fill='#475569' d="M0 192C0 103.6 71.6 32 160 32s160 71.6 160 160V320c0 88.4-71.6 160-160 160S0 408.4 0 320V192zM160 96c-53 0-96 43-96 96V320c0 53 43 96 96 96s96-43 96-96V192c0-53-43-96-96-96z" /></svg>
                        </div>

                        <div className='w-1/12 mx-2 second-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill='#475569' d="M189 77.6c7.5-16 .7-35.1-15.3-42.6s-35.1-.7-42.6 15.3L3 322.4c-4.7 9.9-3.9 21.5 1.9 30.8S21 368 32 368H256v80c0 17.7 14.3 32 32 32s32-14.3 32-32V368h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320V160c0-17.7-14.3-32-32-32s-32 14.3-32 32V304H82.4L189 77.6z" /></svg>
                        </div>
                    </div>

                    <div className='flex flex-row justify-center font-bold text-slate-600'>
                        <label className='text-center text-4xl antialiased rounded-lg p-2 bg-white/60'>Página no encontrada</label>
                    </div>

                    <div className='flex flex-row justify-center font-bold text-slate-600'>
                        <Link to="/home">
                            <button className='bg-slate-600 hover:bg-primaryColor text-white mt-4 py-3 px-5 rounded-lg'>HOME</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}