import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function Options() {

    return (
        <div className='w-full h-full d-flex flex-col justify-center'>

            <div className='w-full flex justify-center mb-4'>
                <Link to="/StatusGuides/guides" className='w-2/4'>
                    <Button className="bg-primaryColor w-full h-14">
                        <span className='mx-3'>AWB</span>
                    </Button>
                </Link>
            </div>

            <div className='w-full flex justify-center mb-4'>
                <Link to="/StatusGuides/carriers" className='w-2/4'>
                    <Button className="bg-primaryColor w-full h-14">
                        <span className='mx-3'>Carrier Module</span>
                    </Button>
                </Link>
            </div>

            <div className='w-full flex justify-center mb-4'>
                <Link to="/StatusGuides/boxesLogs" className='w-2/4'>
                    <Button className="bg-primaryColor w-full h-14">
                        <span className='mx-3'>Boxes</span>
                    </Button>
                </Link>
            </div>

        </div>
    )
}