import { useState, useEffect, useMemo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import './StatusGuides.css'
import Alert from 'react-bootstrap/Alert'
import { Button } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components'

const apiUrl = process.env.REACT_APP_API_URL

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='flex'>
        <TextField
            id="search"
            type="text"
            placeholder="Filter"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </div>
);

export default function StatusGuides() {
    const [info, setInfo] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [startDateCalendar, setStartDateCalendar] = useState()
    const [endDateCalendar, setEndDateCalendar] = useState()
    const [alert, setAlert] = useState()
    const [companyTokens, setCompanyTokens] = useState('')
    const [companyCodes, setCompanyCodes] = useState('')

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = info.filter(
        item =>
            (item.number && item.number.toString().toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.customerName && item.customerName.toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.companyCode && item.companyCode.toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.carrierName && item.carrierName.toLowerCase().includes(filterText.toLowerCase())),
    );

    useEffect(() => {
        const tokens = localStorage.getItem('companyTokens')
        const getCompanyCodes = localStorage.getItem('companyCodes')
        setCompanyTokens(tokens)
        setCompanyCodes(getCompanyCodes)
    }, [companyTokens])

    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ';'
        const lineDelimiter = '\n'
        const keys = Object.keys(array[0])

        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
            let ctr = 0

            keys.forEach(key => {
                if (key !== 'details') {

                    if (ctr > 0) result += columnDelimiter

                    if (typeof (item[key]) === 'string') {
                        result += item[key].replace('#', 'N.')
                    } else {
                        result += item[key]
                    }
                    ctr++
                }
            })
            result += lineDelimiter
        })

        return result
    }

    const downloadCSV = useCallback((array) => {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv == null) return

        const filename = 'export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csvcharset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    }, [])

    const getData = async (from, to) => {
        // const getInfo = JSON.parse(localStorage.getItem('dataTable'))
        // console.log(getInfo)
        // setInfo(getInfo)

        setIsLoading(true)
        setError()

        const storedTokens = JSON.parse(companyTokens)
        const storedCodes = companyCodes ? JSON.parse(companyCodes) : ''

        let getDataPickticket = []
        let cont = 0
        for (let i = 0; i < storedTokens.length; i++) {
            try {
                var myHeaders = new Headers()
                myHeaders.append("Content-Type", "application/json")

                var raw = JSON.stringify({
                    "URL": `/api/pickticket.details.list?authenticationToken=${storedTokens[i]}&orderDateFrom=${from}&orderDateTo=${to}`
                })

                const response = await fetch(`${apiUrl}`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                })

                const data = await response.json();

                if (response.ok) {
                    if (data.message === 'OK') {

                        data.picktickets.map(value => {
                            value.companyCode = storedCodes[i]
                            getDataPickticket.push(value)
                            return null
                        })

                    } else {
                        // setError(data.message)
                        // setInfo([])
                    }
                } else {
                    // setError(data.message)
                    // setInfo([])
                    // setError(`Token ${storedTokens[i]} no valido`)
                }

                setIsLoading(false)
            } catch (error) {
                setError('Error de red. Por favor, inténtalo de nuevo.')
                console.error('Error de red', error)
                setIsLoading(false)
                // setInfo([])
            }

            cont++
        }

        if (storedTokens.length === cont) {
            getDetailsOfBoxes(getDataPickticket)
        }
    }

    const getDetailsOfBoxes = (orders) => {
        const allInfo = orders

        orders.map((details, index) => {
            let readyBoxes = 0
            let pendingBoxes = 0
            let readyBoxesCodes = []
            let pendingBoxesCodes = []

            details.details.map((findBoxes, index) => {

                findBoxes.boxes.map((box) => {
                    // console.log(box)
                    if (box.statusId === 3) {
                        readyBoxes++
                        readyBoxesCodes.push([box.boxCode, box.lotNumber])
                    } else {
                        pendingBoxes++
                        pendingBoxesCodes.push([box.boxCode, box.lotNumber])
                    }

                    return null
                })

                return null
            })

            allInfo[index].readyBoxes = readyBoxes
            allInfo[index].pendingBoxes = pendingBoxes
            allInfo[index].readyBoxesCodes = readyBoxesCodes
            allInfo[index].pendingBoxesCodes = pendingBoxesCodes

            return null
        })

        // console.log(allInfo)
        setInfo(allInfo)
        localStorage.setItem('dataTable', JSON.stringify(info))
    }

    const columns = [
        {
            name: 'Order',
            selector: row => row.number,
            sortable: true,
            wrap: true,
            maxWidth: '110px',
            style: {
                justifyContent: 'center',
                textAlign: 'center'
            }
        },
        {
            name: 'CompanyCode',
            selector: row => row.companyCode,
            sortable: true,
            wrap: true,
            maxWidth: '160px',
            style: {
                justifyContent: 'center',
                textAlign: 'center'
            }
        },
        {
            name: 'customerName',
            selector: row => row.customerName,
            sortable: true,
            wrap: true
        },
        {
            name: 'confirmedOn',
            selector: row => row.confirmedOn.split('T')[0],
            sortable: true,
            wrap: true
        },
        {
            name: 'pendingBoxes',
            selector: row => row.pendingBoxes,
            sortable: true,
            wrap: true
        },
        {
            name: 'Estado',
            selector: row => {
                switch (row.status) {
                    case 'Confirmed':
                        return <p className='bg-success m-0 text-white px-2 py-1 rounded-lg font-bold'>{row.status}</p>

                    case 'Pending Approval':
                        return <p className='bg-warning m-0 text-white px-2 py-1 rounded-lg font-bold'>{row.status}</p>

                    default:
                        return <p className='bg-secondary m-0 text-white px-2 py-1 rounded-lg font-bold'>{row.status}</p>
                }
            },
            sortable: true,
            style: {
                justifyContent: 'center',
                textAlign: 'center'
            },
            maxWidth: '130px',
            wrap: true
        }
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#0288D1',
                whiteSpace: 'wrap'
            }
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                backgroundColor: '#0288D1',
                color: 'white'
            }
        },
        rows: {
            style: {},
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(2 136 209 / 20%)',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
        cells: {
            style: {},
        },
    }

    const conditionalRowStyles = [
        {
            when: row => row.pendingBoxes <= 0,
            style: {
                backgroundColor: '#22c55e',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ]

    function filterDate() {
        if (startDateCalendar && endDateCalendar) {
            const from = formatDate(startDateCalendar)
            const to = formatDate(endDateCalendar)

            getData(from, to)
        } else {
            setAlert('empty fields')
        }
    }

    function formatDate(date = new Date()) {
        const year = date.toLocaleString('default', { year: 'numeric' });
        const month = date.toLocaleString('default', {
            month: '2-digit',
        });
        const day = date.toLocaleString('default', { day: '2-digit' });

        return [year, month, day].join('-');
    }

    function addDays(date, days) {
        if (date) {
            date.setDate(date.getDate() + days);
            return date;
        }
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey)

        return (
            <button
                type="button"
                className='w-full text-start px-3 py-2 hover:cursor-pointer'
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        )
    }

    const ExpandedComponent = ({ data }) => (
        <div className='w-full px-10 py-4'>
            <div className='flex '>
                <div className='flex w-full flex-col'>
                    <div>
                        <p className='p-0 m-0'>Order</p>
                        <p className='text-2xl font-bold px-10'>{data.number}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Company Code</p>
                        <p className='text-2xl font-bold px-10'>{data.companyCode}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Total Boxes</p>
                        <p className='text-2xl font-bold px-10'>{data.totalBoxes}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Ready Boxes</p>
                        <p className='text-2xl font-bold px-10'>{data.readyBoxes}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Pending Boxes</p>
                        <p className='text-2xl font-bold px-10'>{data.pendingBoxes}</p>
                    </div>
                </div>
                <div className='flex w-full flex-col'>
                    <div>
                        <p className='p-0 m-0'>Ship City</p>
                        <p className='text-lg font-bold px-10'>{data.shipCity}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Carrier Name</p>
                        <p className='text-lg font-bold px-10'>{data.carrierName}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>Sales Person Name</p>
                        <p className='text-lg font-bold px-10'>{data.salesPersonName}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>confirmedOn</p>
                        <p className='text-lg font-bold px-10'>{data.confirmedOn.split('T')[0]}</p>
                    </div>
                </div>
            </div>

            <Accordion className='mb-3'>

                {
                    data.readyBoxesCodes.length ?
                        <Card>
                            <Card.Header className='hover:bg-darkPrimaryColor p-0 font-bold hover:text-white duration-300'>
                                <CustomToggle eventKey="0">Ready box codes</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className='flex flex-wrap'>
                                        {
                                            data.readyBoxesCodes.map((value, index) => {
                                                return (
                                                    <OverlayTrigger
                                                        key={index}
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id={`tooltip-${value[0]}`}>
                                                                click to copy
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span key={index} onClick={() => navigator.clipboard.writeText(value[0])} className="bg-blue-100 text-darkPrimaryColor font-medium text-sm mx-1 my-1 px-3 py-2 rounded border border-darkPrimaryColor hover:cursor-pointer hover:bg-darkPrimaryColor hover:text-white">{`${value[0]} - Lot: ${value[1]}`}</span>
                                                    </OverlayTrigger>
                                                )
                                            })
                                        }
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        :
                        <></>
                }

                {
                    data.pendingBoxesCodes.length ?
                        <Card>
                            <Card.Header className='hover:bg-darkPrimaryColor p-0 font-bold hover:text-white duration-300'>
                                <CustomToggle eventKey="1">Pending box codes</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div className='flex flex-wrap'>
                                        {
                                            data.pendingBoxesCodes.map((value, index) => {
                                                return (
                                                    <OverlayTrigger
                                                        key={index}
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id={`tooltip-${value[0]}`}>
                                                                click to copy
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span key={index} onClick={() => navigator.clipboard.writeText(value[0])} className="bg-secondaryColor text-secondaryText font-medium text-sm mx-1 my-1 px-3 py-2 rounded border border-secondaryText hover:cursor-pointer hover:bg-secondaryText hover:text-white">{`${value[0]} - Lot: ${value[1]}`}</span>
                                                    </OverlayTrigger>
                                                )
                                            })
                                        }
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        :
                        <></>
                }
            </Accordion>
        </div >
    )

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        }

        return (
            <>
                {info.length ?
                    <>
                        <Button className='m-2 bg-blue-200' onClick={() => downloadCSV(info)}>Descargar CSV</Button>
                        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </>
                    :
                    <></>
                }
            </>
        );
    }, [filterText, resetPaginationToggle, info, downloadCSV]);

    return (
        <>
            {error && (
                <Alert variant='danger' className='text-sm'>
                    {error}
                </Alert>
            )}

            <div className='text-center m-4'>
                <p className='font-black text-2xl'>AWB</p>
            </div>

            <div className='z-10 mb-8'>
                <div className='flex flex-col md:flex-row justify-center '>
                    <div className='flex flex-col'>
                        <label className='font-bold px-2'>Start date</label>
                        <DatePicker
                            selected={startDateCalendar}
                            onChange={(date) => {
                                setStartDateCalendar(date)
                                setEndDateCalendar()
                                setAlert()
                            }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />
                    </div>
                    <div className='mx-2 pb-2 flex justify-center items-end'>
                        <span className='text-xl font-bold md:block hidden'>~</span>
                    </div>
                    <div className='flex flex-col'>
                        <label className='font-bold px-2'>End date</label>
                        <DatePicker
                            selected={endDateCalendar}
                            onChange={(date) => {
                                setEndDateCalendar(date)
                                setAlert()
                            }}
                            minDate={startDateCalendar}
                            maxDate={addDays(new Date(startDateCalendar), 7)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />
                    </div>
                </div>

                <div className='flex justify-center mt-4'>
                    {alert && (
                        <Alert variant='danger' className='text-sm'>
                            {alert}
                        </Alert>
                    )}
                </div>

                <div className='flex justify-center my-2'>
                    <Button className=' bg-blue-200' onClick={() => filterDate()}>Buscar</Button>
                </div>
            </div>


            {
                isLoading ?
                    <div className='flex justify-center items-center flex-col'>
                        <Spinner animation="grow" variant="primary" />
                        <span className='text-dividerColor'>Loading...</span>
                    </div>
                    :
                    <div>
                        <DataTable
                            columns={columns}
                            // data={info}
                            data={filteredItems}
                            customStyles={customStyles}
                            fixedHeader
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            paginationResetDefaultPage={resetPaginationToggle}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
            }

        </>
    )
}