import { useState, useEffect, useMemo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import './StatusGuides.css'
import Alert from 'react-bootstrap/Alert'
import { Button } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components'

const apiUrl = process.env.REACT_APP_API_URL

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='flex'>
        <TextField
            id="search"
            type="text"
            placeholder="Filter"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </div>
);

export default function StatusBoxes() {
    const [info, setInfo] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [startDateCalendar, setStartDateCalendar] = useState()
    const [endDateCalendar, setEndDateCalendar] = useState()
    const [alert, setAlert] = useState()
    const [companyTokens, setCompanyTokens] = useState('')
    const [boxCode, setBoxeCode] = useState('')

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = info.filter(
        item =>
            (item.boxCode && item.boxCode.toString().toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.date && item.date.toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.lotNumber && item.lotNumber.toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.user && item.user.toLowerCase().includes(filterText.toLowerCase())),
    );

    useEffect(() => {
        const tokens = localStorage.getItem('companyTokens')
        setCompanyTokens(tokens)
    }, [companyTokens])

    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ';'
        const lineDelimiter = '\n'
        const keys = Object.keys(array[0])

        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
            let ctr = 0

            keys.forEach(key => {
                if (key !== 'details') {

                    if (ctr > 0) result += columnDelimiter

                    if (typeof (item[key]) === 'string') {
                        result += item[key].replace('#', 'N.')
                    } else {
                        result += item[key]
                    }
                    ctr++
                }
            })
            result += lineDelimiter
        })

        return result
    }

    const downloadCSV = useCallback((array) => {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv == null) return

        const filename = 'export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csvcharset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    }, [])

    const getData = async (from, to, box) => {

        setIsLoading(true)
        setError()

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")

        try {
            var raw = JSON.stringify({
                'boxCode': box,
                'from': from,
                'to': to
            })

            const response = await fetch(`${apiUrl}/searchBoxLog`, {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            })

            const data = await response.json()
            if (response.ok) {
                if (data.found) {
                    setInfo(data.result)
                } else {
                    setInfo([])
                }
            } else {
                setError(['Error', 'danger'])
            }

            setIsLoading(false)

        } catch (error) {
            setError('Error de red. Por favor, inténtalo de nuevo.')
            setIsLoading(false)
        }
    }

    const columns = [
        {
            name: 'BoxCode',
            selector: row => row.boxCode,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date',
            selector: row => `${row.date.split('T')[0]} ${row.date.split('T')[1].split('.')[0]}`,
            sortable: true,
            wrap: true
        },
        {
            name: 'lotNumber',
            selector: row => row.lotNumber,
            sortable: true,
            wrap: true
        },
        {
            name: 'Who scanned',
            selector: row => row.user,
            sortable: true,
            wrap: true
        }
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#0288D1',
                whiteSpace: 'wrap'
            }
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                backgroundColor: '#0288D1',
                color: 'white'
            }
        },
        rows: {
            style: {},
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(2 136 209 / 20%)',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
        cells: {
            style: {},
        },
    }

    const conditionalRowStyles = [
        {
            when: row => row.pendingBoxes <= 0,
            style: {
                backgroundColor: '#22c55e',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ]

    function searchBox(e) {
        e && e.preventDefault()

        if ((startDateCalendar && endDateCalendar) || boxCode) {
            const from = startDateCalendar ? formatDate(startDateCalendar) : ''
            const to = endDateCalendar ? formatDate(endDateCalendar) : ''

            setAlert()

            getData(from, to, boxCode)
        } else {
            setAlert('empty fields')
        }
    }

    function formatDate(date = new Date()) {
        const year = date.toLocaleString('default', { year: 'numeric' });
        const month = date.toLocaleString('default', {
            month: '2-digit',
        });
        const day = date.toLocaleString('default', { day: '2-digit' });

        return [year, month, day].join('-');
    }

    function addDays(date, days) {
        if (date) {
            date.setDate(date.getDate() + days);
            return date;
        }
    }

    // const ExpandedComponent = ({ data }) => (
    //     <div className='w-full px-10 py-4'>

    //     </div >
    // )

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        }

        return (
            <>
                {info.length ?
                    <>
                        <Button className='m-2 bg-blue-200' onClick={() => downloadCSV(info)}>Descargar CSV</Button>
                        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </>
                    :
                    <></>
                }
            </>
        );
    }, [filterText, resetPaginationToggle, info, downloadCSV]);

    return (
        <>
            {error && (
                <Alert variant='danger' className='text-sm'>
                    {error}
                </Alert>
            )}

            <div className='text-center m-4'>
                <p className='font-black text-2xl'>Boxes</p>
            </div>

            <form>
                <div className='w-full flex justify-center mb-3'>
                    <div className='m-2'>
                        <label htmlFor="boxCode" className="font-bold px-2">
                            BoxCode
                        </label>
                        <div className="group">
                            <input type="text"
                                id="boxCode"
                                className="input"
                                name='boxCode'
                                value={boxCode}
                                onChange={(e) => {
                                    setBoxeCode(e.target.value)
                                }}
                                placeholder=''
                                autoComplete="off"
                                autoFocus />
                        </div>
                    </div>
                </div>

                <div className='z-10 mb-8'>
                    <div className='flex flex-col md:flex-row justify-center '>
                        <div className='flex flex-col'>
                            <label className='font-bold px-2'>Start date</label>
                            <DatePicker
                                selected={startDateCalendar}
                                onChange={(date) => {
                                    setStartDateCalendar(date)
                                    setEndDateCalendar()
                                    setAlert()
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            />
                        </div>
                        <div className='mx-2 pb-2 flex justify-center items-end'>
                            <span className='text-xl font-bold md:block hidden'>~</span>
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-bold px-2'>End date</label>
                            <DatePicker
                                selected={endDateCalendar}
                                onChange={(date) => {
                                    if (startDateCalendar) {
                                        setEndDateCalendar(date)
                                    }
                                    setAlert()
                                }}
                                minDate={startDateCalendar}
                                maxDate={addDays(new Date(startDateCalendar), 30)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            />
                        </div>
                    </div>

                    <div className='flex justify-center mt-4'>
                        {alert && (
                            <Alert variant='danger' className='text-sm'>
                                {alert}
                            </Alert>
                        )}
                    </div>

                    <div className='flex justify-center my-2'>
                        <Button type='submit' className=' bg-blue-200' onClick={(e) => searchBox(e)}>Buscar</Button>
                    </div>
                </div>
            </form>


            {
                isLoading ?
                    <div className='flex justify-center items-center flex-col'>
                        <Spinner animation="grow" variant="primary" />
                        <span className='text-dividerColor'>Loading...</span>
                    </div>
                    :
                    <div>
                        <DataTable
                            columns={columns}
                            // data={info}
                            data={filteredItems}
                            customStyles={customStyles}
                            fixedHeader
                            // expandableRows
                            // expandableRowsComponent={ExpandedComponent}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            paginationResetDefaultPage={resetPaginationToggle}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
            }

        </>
    )
}