import { useEffect, useState, useMemo, useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

// URL de la API y token de la empresa desde el entorno React
const apiUrl = process.env.REACT_APP_API_URL

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='flex'>
        <TextField
            id="search"
            type="text"
            placeholder="Filter"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </div>
);

var myHeaders = new Headers()
myHeaders.append("Content-Type", "application/json")

// Componente principal AddUsers
export default function Users() {
    // Estados
    const [alert, setAlert] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [info, setInfo] = useState([])

    useEffect(() => {
        setIsLoading(true)
        getUsers()
    }, [])



    const getUsers = async () => {
        try {
            const response = await fetch(`${apiUrl}/getUsers`, {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            })

            const data = await response.json()
            if (response.ok) {
                let result = data.result

                try {
                    const response = await fetch(`${apiUrl}/getTokens`, {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    })

                    const data = await response.json()
                    if (response.ok) {
                        let tokens = data.result

                        for (let i = 0; i < result.length; i++) {
                            let IdToken = result[i].company.split(',')

                            result[i].tokens = []

                            IdToken.map((value) => {
                                let setInfoToken = tokens.filter((arr) => arr.ID === parseInt(value))

                                if(setInfoToken.length){
                                    result[i].tokens.push({ ID: setInfoToken[0].ID, name: setInfoToken[0].name, token: setInfoToken[0].token })
                                }

                                return null
                            })

                        }

                        setInfo(result)

                        setIsLoading(false)

                    } else {
                        setAlert([data, 'danger'])
                        console.error(data)
                    }
                } catch (error) {
                    setAlert([error, 'danger'])
                    console.error('Error de red', error)
                }
            } else {
                setAlert([data, 'danger'])
                console.error(data)
            }
        } catch (error) {
            setAlert([error, 'danger'])
            console.error('Error de red', error)
        }
    }

    const deleteUser = async (idUser, login) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger mr-2",
                cancelButton: "btn btn-secondary"
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Delete user?",
            text: `User: ${login}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    var raw = JSON.stringify({
                        'login': login
                    })

                    const response = await fetch(`${apiUrl}/deleteUser`, {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    })

                    const data = await response.json()
                    if (response.ok) {
                        if (data.correctResponse) {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                width: 300,
                                backdrop: false,
                                text: data.result,
                                showConfirmButton: false,
                                timer: 2000
                            })

                            getUsers()
                        } else {
                            setAlert([data.result, 'danger'])
                        }

                    } else {
                        setAlert([data, 'danger'])
                        console.error(data)
                    }
                } catch (error) {
                    setAlert([error, 'danger'])
                    console.error('Error de red', error)
                }

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.close()
            }
        })

    }


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = info.filter(
        item =>
            (item.ID && item.ID.toString().toLowerCase().includes(filterText.toLowerCase()))
            ||
            (item.login && item.login.toLowerCase().includes(filterText.toLowerCase())),
    )

    const columns = [
        {
            name: 'ID',
            selector: row => row.ID,
            sortable: true,
            wrap: true,
            maxWidth: '110px',
            style: {
                justifyContent: 'center',
                textAlign: 'center'
            }
        },
        {
            name: 'User',
            selector: row => row.login,
            sortable: true,
            wrap: true
        },
        {
            name: 'Action',
            selector: row =>
                <>
                    <Link to={`/users/addUsers?ID=${row.ID}&login=${row.login}&company=${row.company}`} className='text-black'>
                        <FontAwesomeIcon icon={faPen} className='bg-dividerColor hover:bg-darkPrimaryColor hover:text-white cursor-pointer rounded m-1 px-2 py-2 fa-1x' />
                    </Link>
                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteUser(row.ID, row.login)} className=' bg-dividerColor hover:bg-darkPrimaryColor hover:text-white cursor-pointer rounded m-1 px-2 py-2 fa-1x' />
                </>,
            wrap: true
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#0288D1',
                whiteSpace: 'wrap'
            }
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                backgroundColor: '#0288D1',
                color: 'white'
            }
        },
        rows: {
            style: {},
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(2 136 209 / 20%)',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
        cells: {
            style: {},
        },
    }

    const conditionalRowStyles = []

    const downloadCSV = useCallback((array) => {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv == null) return

        const filename = 'export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csvcharset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    }, [])

    const ExpandedComponent = ({ data }) => (
        <div className='w-full px-10 py-4'>
            <div className='flex '>
                <div className='flex w-full flex-col'>
                    <div>
                        <p className='p-0 m-0'>ID</p>
                        <p className='text-2xl font-bold px-10'>{data.ID}</p>
                    </div>
                    <div>
                        <p className='p-0 m-0'>User</p>
                        <p className='text-2xl font-bold px-10'>{data.login}</p>
                    </div>
                </div>
                <div className='flex w-full flex-col justify-center'>
                    <div>
                        <p className='p-0 m-0'>{data.tokens.length > 1 ? 'Tokens' : 'Token'}</p>
                        <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg p-0">
                            {data.tokens.map((value, index) => {
                                return <li key={value.ID} className="w-full border-b border-gray-200 rounded-t-lg cursor-pointer">
                                    <div className="flex items-center ps-3 cursor-pointer">
                                        <label htmlFor={`token-${value.ID}`} className="w-full py-3 pl-4 ms-2 text-sm font-medium text-gray-900">ID: {value.ID}<br />Company Name: {value.name}<br />Token : {value.token}</label>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        }

        return (
            <>
                {info.length ?
                    <>
                        <Button className='m-2 bg-blue-200' onClick={() => downloadCSV(info)}>Descargar CSV</Button>
                        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </>
                    :
                    <></>
                }
            </>
        );
    }, [filterText, resetPaginationToggle, info, downloadCSV]);


    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ';'
        const lineDelimiter = '\n'
        const keys = Object.keys(array[0])

        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
            let ctr = 0

            keys.forEach(key => {
                if (key !== 'details') {

                    if (ctr > 0) result += columnDelimiter

                    if (typeof (item[key]) === 'string') {
                        result += item[key].replace('#', 'N.')
                    } else {
                        result += item[key]
                    }
                    ctr++
                }
            })
            result += lineDelimiter
        })

        return result
    }

    const titleDataTable = (
        <div className='flex flex-col md:flex-row justify-center'>
            <div className='flex flex-col'>
                <label className='font-bold px-2'>Users</label>
            </div>
        </div>
    )

    return (
        <div>
            <div className='w-full flex justify-center mb-5'>
                <Link to="/users/addUsers">
                    <Button className="bg-primaryColor">
                        <span className='mx-3'>Create User</span>
                    </Button>
                </Link>
            </div>

            <div className='flex justify-center'>
                {alert && (
                    <Alert variant={alert[1]} className='text-sm'>
                        {alert[0]}
                    </Alert>
                )}
            </div>

            {
                isLoading ?
                    <div className='flex justify-center items-center flex-col'>
                        <Spinner animation="grow" variant="primary" />
                        <span className='text-dividerColor'>Loading...</span>
                    </div>
                    :
                    <div>
                        <DataTable
                            title={titleDataTable}
                            columns={columns}
                            // data={info}
                            data={filteredItems}
                            customStyles={customStyles}
                            fixedHeader
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            paginationResetDefaultPage={resetPaginationToggle}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
            }
        </div>
    )
}