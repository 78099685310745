// components/Navbar/Navbar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faCaretUp, faCaretDown, faRightFromBracket, faBoxesStacked, faList, faUsers, faKey, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@react-hook/media-query';
import './Navbar.css';
import BtnsNavbar from './BtnsNavbar'

const Navbar = ({ onLogout, admin }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [isAdmin, setIsAdmin] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    onLogout();
  };

  const handleSideBar = () => {
    isMobile && setSidebarOpen(false)
  };

  useEffect(() => {
    isMobile && setSidebarOpen(false)

    const getUser = localStorage.getItem('user')

    if (getUser) {
      if (getUser.includes('admin')) {
        admin(true)
        setIsAdmin(true)
      } else {
        admin(false)
        setIsAdmin(false)
      }
    }
  }, [isMobile, admin])

  return (
    <div className={`flex flex-col ${isMobile ? isSidebarOpen ? 'w-full h-screen p-2 fixed z-10' : 'h-10 p-0' : `h-screen ${isSidebarOpen ? 'w-72 min-w-72 p-2' : 'w-16 p-2'}`} bg-darkPrimaryColor text-white  transition-all duration-300 justify-between`}>
      <button
        className="customTooltip text-white p-2 rounded-md hover:bg-primaryColor focus:outline-none focus:ring focus:border-lightPrimaryColor transition-all duration-300"
        onClick={handleToggleSidebar}
      >
        {isSidebarOpen ?
          (
            <>
              <FontAwesomeIcon icon={isMobile ? faCaretUp : faCaretLeft} />
              {isSidebarOpen ?
                (
                  <span className='pl-2'>Contraer</span>
                )
                :
                (
                  <div className="tooltiptext">Cerrar sesión</div>
                )
              }
            </>
          )
          :
          (
            <>
              <FontAwesomeIcon icon={isMobile ? faCaretDown : faCaretRight} />
              {isSidebarOpen ?
                (
                  <span className='pl-2'>Contraer</span>
                )
                :
                (
                  <div className={`tooltiptext ${isMobile && 'hidden'}`}>Extender</div>
                )
              }
            </>
          )
        }
      </button>

      {/* Buttons all tools */}
      <div>
        {/* <Link to="/home">
          <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faHome} textBtn='Home'handleAction={handleSideBar} />
        </Link> */}

        <Link to="/guides">
          <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faBoxesStacked} textBtn='Invoice' handleAction={handleSideBar} />
        </Link>

        <Link to="/statusGuides">
          <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faList} textBtn='Invoice status' handleAction={handleSideBar} />
        </Link>

        {
          isAdmin ?
            <>
              <Link to="/users">
                <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faUsers} textBtn='Users' handleAction={handleSideBar}/>
              </Link>

              <Link to="/addToken">
                <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faKey} textBtn='Add Token' handleAction={handleSideBar} />
              </Link>

              <Link to="/getBoxes">
                <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} icon={faDownload} textBtn='Process Get BoxCode' handleAction={handleSideBar} />
              </Link>
            </>
            :
            <></>
        }
      </div>

      <Link to="/">
        <BtnsNavbar isMobile={isMobile} isSidebarOpen={isSidebarOpen} handleAction={handleLogout} icon={faRightFromBracket} textBtn='Cerrar sesión' />
      </Link>
    </div>
  );
};

export default Navbar;
