import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

// URL de la API y token de la empresa desde el entorno React
const apiUrl = process.env.REACT_APP_API_URL

var myHeaders = new Headers()
myHeaders.append("Content-Type", "application/json")

// Componente principal AddToken
export default function AddToken() {
    // Estados
    const [alert, setAlert] = useState('')
    const [token, setToken] = useState('')
    const [tokenID, setTokenID] = useState('')
    const [companyCode, setCompanyCode] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [tokens, setTokens] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEditToken, setIsEditToken] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        getTokens()
    }, [])

    const getTokens = async () => {
        try {
            const response = await fetch(`${apiUrl}/getTokens`, {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            })

            const data = await response.json()
            if (response.ok) {
                // console.log(data)
                setTokens(data.result)
                setIsLoading(false)
            } else {
                setAlert([data, 'danger'])
                console.error(data)
            }
        } catch (error) {
            setAlert([error, 'danger'])
            console.error('Error de red', error)
        }
    }


    const saveToken = async () => {

        setAlert()

        if (token.length > 5 && companyName.length >= 4) {
            try {
                var raw = JSON.stringify({
                    'tokenID': tokenID,
                    'name': companyName,
                    'code': companyCode,
                    'token': token
                })

                const response = await fetch(`${apiUrl}/${isEditToken ? 'updateToken' : 'addToken'}`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                })

                const data = await response.json()
                if (response.ok) {
                    console.log(data)

                    if (data.correctResponse) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            width: 300,
                            backdrop: false,
                            text: data.result,
                            showConfirmButton: false,
                            timer: 2000
                        })

                        cancelEdit()

                    } else {
                        setAlert([data.result, 'danger'])
                    }

                } else {
                    setAlert([data, 'danger'])
                    console.error(data)
                }
            } catch (error) {
                setAlert([error, 'danger'])
                console.error('Error de red', error)
            }
        } else {
            setAlert(['Fields must be greater than 5 characters', 'danger'])
        }

    }

    const deleteToken = async (tokenID, tokenName, token) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger mr-2",
                cancelButton: "btn btn-secondary"
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Delete Token?",
            html: `Company: ${tokenName} <br/> Token: ${token}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    var raw = JSON.stringify({
                        'tokenID': tokenID
                    })

                    const response = await fetch(`${apiUrl}/deleteToken`, {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    })

                    const data = await response.json()
                    if (response.ok) {

                        if (data.correctResponse) {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                width: 300,
                                backdrop: false,
                                text: data.result,
                                showConfirmButton: false,
                                timer: 2000
                            })

                            setCompanyName('')
                            setCompanyCode('')
                            setToken('')
                            getTokens()

                        } else {
                            setAlert([data.result, 'danger'])
                        }

                    } else {
                        setAlert([data, 'danger'])
                        console.error(data)
                    }
                } catch (error) {
                    setAlert([error, 'danger'])
                    console.error('Error de red', error)
                }

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.close()
            }
        })
    }

    const editToken = async (tokenID, tokenCode, tokenName, token) => {
        setIsEditToken(true)

        setTokenID(tokenID)
        setCompanyName(tokenName)
        setCompanyCode(tokenCode)
        setToken(token)

        getTokens()
    }

    const cancelEdit = async (tokenCode, tokenName, token) => {
        setIsEditToken(false)

        setCompanyName('')
        setCompanyCode('')
        setToken('')

        getTokens()
    }

    return (
        <div>
            <div className='flex flex-col md:flex-row justify-center'>
                <div className='flex flex-col'>
                    <label className='font-bold px-2'>{isEditToken ? 'Edit' : 'Add'} Token</label>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='m-2'>
                    <label htmlFor="companyName" className="text-sm font-medium text-gray-600">
                        Company name
                    </label>
                    <div className="group">
                        <input type="text"
                            id="companyName"
                            className="input"
                            name='companyName'
                            value={companyName}
                            onChange={(e) => {
                                setCompanyName(e.target.value)
                                setAlert()
                            }}
                            placeholder=''
                            autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='m-2'>
                    <label htmlFor="companyCode" className="text-sm font-medium text-gray-600">
                        Company Code
                    </label>
                    <div className="group">
                        <input type="text"
                            id="companyCode"
                            className="input"
                            name='companyCode'
                            value={companyCode}
                            onChange={(e) => {
                                setCompanyCode(e.target.value.toUpperCase())
                                setAlert()
                            }}
                            placeholder=''
                            autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='m-2 w-full flex justify-center items-center flex-col'>
                    <label htmlFor="token" className="text-sm font-medium text-gray-600">
                        Token
                    </label>
                    <div className="group flex justify-center w-2/4">
                        <input type="text"
                            id="token"
                            className="input w-full"
                            name='token'
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value)
                                setAlert()
                            }}
                            placeholder=''
                            autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className='flex justify-center'>
                {alert && (
                    <Alert variant={alert[1]} className='text-sm'>
                        {alert[0]}
                    </Alert>
                )}
            </div>

            <div className='w-full flex justify-center mt-3 mb-4'>
                <Button className="bg-primaryColor" onClick={() => saveToken()}>
                    <span className='mx-3'>{isEditToken ? 'Edit' : 'Save'} token</span>
                </Button>
                {
                    isEditToken ?
                        <Button className="ml-4 bg-secondary" onClick={() => cancelEdit()}>
                            <span className='mx-3'>Cancel</span>
                        </Button>
                        :
                        <></>
                }
            </div>

            {
                isEditToken ?
                    <></>
                    :
                    <>
                        <div className='flex flex-col md:flex-row justify-center mt-3'>
                            <div className='flex flex-col'>
                                <label className='font-bold px-2'>Tokens</label>
                            </div>
                        </div>

                        <div className='w-full flex justify-center'>
                            {
                                isLoading ?
                                    <div className='flex justify-center items-center flex-col m-4'>
                                        <Spinner animation="grow" variant="primary" />
                                        <span className='text-dividerColor'>Loading...</span>
                                    </div>
                                    :
                                    <ul className="w-full mx-4 md:w-3/5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg p-0 overflow-auto max-h-96">
                                        {tokens.map((value) => {
                                            return <li key={value.ID} className="w-full border-b border-gray-200 rounded-t-lg cursor-pointer">
                                                <div className="flex items-center ps-3 cursor-pointer">
                                                    <label htmlFor={`token-${value.ID}`} className="w-full py-3 pl-4 ms-2 text-sm font-medium text-gray-900">ID: {value.ID}<br />Company Code: {value.companyCode}<br />Company Name: {value.name}<br />Token : {value.token}</label>
                                                    <FontAwesomeIcon icon={faPen} onClick={() => editToken(value.ID, value.companyCode, value.name, value.token)} className='bg-dividerColor hover:bg-darkPrimaryColor hover:text-white cursor-pointer rounded m-1 px-2 py-2 fa-1x' />
                                                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteToken(value.ID, value.name, value.token)} className=' bg-dividerColor hover:bg-darkPrimaryColor hover:text-white cursor-pointer rounded m-1 px-2 py-2 fa-1x' />
                                                </div>
                                            </li>
                                        })}
                                    </ul>
                            }
                        </div>
                    </>
            }

        </div>
    )
}