import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BtnsNavbar({ isMobile, isSidebarOpen, handleAction, icon, textBtn }) {
    return (
        <button
            className={`${isMobile ? isSidebarOpen ? '' : 'hidden' : ''} customTooltip customTooltip w-full text-white p-2 rounded-md hover:bg-primaryColor focus:outline-none focus:ring focus:border-lightPrimaryColor transition-all duration-300`}
            onClick={handleAction}
        >

            <FontAwesomeIcon icon={icon} />

            {isSidebarOpen ?
                (
                    <span className='pl-2'>{textBtn}</span>
                )
                :
                (
                    <div className={`tooltiptext ${isMobile && 'hidden'}`}>{textBtn}</div>
                )
            }

        </button>
    )
}